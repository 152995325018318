<template>
   <section class="gen-section-padding-3 gen-single-video">
      <div class="container">
         <div class="row no-gutters">
            <div class="col-lg-12">
               <div class="row">
                  <div class="col-lg-12">
                            <div class="pm-inner">
                                <div class="gen-more-like">
                                    <h5 class="gen-more--search"> نتائج البحث عن : {{ this.$route.params.search_id }}</h5>
                                    <div class="row post-loadmore-wrapper">
                                        <div class="col-xl-3 col-lg-4 col-md-6" v-for="publish in searchData" :key="publish.id">

                                            <div class="gen-carousel-movies-style-1 movie-grid style-1">
                                <div class="gen-movie-contain" @click="getFeed(publish)">
                                  <div class="gen-movie-img" >
                                    <a  @click="getFeed(publish)" >
                                        <img v-lazy="publish.carsoul_img" alt="single-video-image">
                                        
                                        
                                        <div class="gen-movie-action">
                                            <div  class="gen-button">
                                                <svg fill="#fff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26">
                                                <polygon  points="9.33 6.69 9.33 19.39 19.3 13.04 9.33 6.69"/>
                                                <path fill="none" d="M26,13A13,13,0,1,1,13,0,13,13,0,0,1,26,13ZM13,2.18A10.89,10.89,0,1,0,23.84,13.06,10.89,10.89,0,0,0,13,2.18Z"/>
                                                </svg> 
                                            </div>
                                        </div>
                                        </a>
                                        <div class="gen-info-contain">
                                        <div class="gen-movie-info">
                                            <h3><a @click="getFeed(publish)">{{publish.title_ar}}</a></h3>
                                        </div>
                                        <div class="gen-movie-meta-holder">
                                            <ul>
                                                <li>{{publish.year}}</li>
                                                <li>
                                                    <a @click="getFeed(publish)"><span>{{ publish.name_ar }}</span></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
               </div>
            </div>
         </div>
      </div>
   </section>
</template>

<script>
import { HTTP } from '@/Api/http-common';
import { useCookie } from 'vue-cookie-next'
import { ref } from '@vue/reactivity';
import { useRouter, useRoute } from "vue-router"
import { onMounted } from '@vue/runtime-core';
export default {
   name: 'AppSearchs',
   props: {
       search_id: String,
    },
     setup(props) {
      const cookie = useCookie()
      const router = useRouter();
      const route = useRoute();
      const searchData = ref([]);
      const GetSearch = async () => {
          await HTTP.get('GetSearchContent.php?search='+ props.search_id).then((res) => {
              searchData.value = res.data.Content;
           })
      }
      onMounted(() => {
          GetSearch();
      });
      const getFeed = (publish) => {
          cookie.setCookie('cat_id', publish.cat_id);
          router.push({ name: "Contents", params: { content_id: publish.id } });
      };
      return {
        searchData,
        getFeed
      };
  },
}
</script>

<style>

</style>